import logo from './logo.svg';
import './App.css';
import Router from './router';
window.shareData = {
  title: '小黄人隐藏限定好礼大放送！', // 分享标题
  desc: '60s亲测幸福关键词，2021北京环球度假区小黄人乐园等你！', // 分享描述
  link:  '', // 分享链接
  imgUrl: 'http://test.tron-m.com/campaign/resource/images/share.png', // 分享图标
  type: 'link', // 分享类型,music、video或link，不填默认为link
  dataUrl: '', // 如果type是music或video，则要提供数据链接，默认为空
  success: function () {
     
  },
  cancel: function () {
      // 用户取消分享后执行的回调函数
    
  }
};
function App() {
  return <Router/>
}

export default App;
