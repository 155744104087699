import React from 'react';
import routes from './routes';
import {
  HashRouter,
  Route,
  Redirect,
  Switch,
  BrowserRouter,
} from 'react-router-dom';
import Home from '../pages/Index/view';
import mapComponent from './mapComponent';

class Router extends React.Component {
  render() {
    console.log(routes);
    return (
      <HashRouter basename="/">
        <main>
          <Switch>
            {routes.map((route, index) => {
              return (
                <Route
                  key={index}
                  exact
                  {...route}
                  component={mapComponent(route.component)}
                />
              );
            })}
            <Redirect to="/" />
          </Switch>
        </main>
      </HashRouter>
    );
  }
}

export default Router;
