export default [
  {
    title: '首页',
    path: '/',
    component:()=>import('@/pages/Index'),
  },
  {
    title: '表单页',
    path: '/footprint',
    component:()=>import('@/pages/Footprint_page'),
  },
  {
    title: '传递页面',
    path: '/transmit_page',
    component:()=>import('@/pages/transmit_page'),
  },
  {
    title: 'f分享合作',
    path: '/partner',
    component:()=>import('@/pages/partner'),
  },
 
]