/**
 * 【-首页】
 */
import React from 'react';
import styles from './index.module.scss';
import bgGIF from '@/assets/images/index_bg.gif';
import cz from '@/assets/images/cz.png';
import czBg from '@/assets/images/cz_bg.png';
import czOne from '@/assets/images/cz_one.png';

const View = (props) => {

  return (
    <div className={styles.home}>
      <img src={bgGIF} alt="" />
      <div className={styles.content}>
        <div className={styles.slogan}>
          <p className={styles.one}>你好，阿里人</p>
          <p className={styles.two}>还记得你那颗非凡初心吗？</p>
          <p className={styles.three}>让我们一起点亮</p>
          <p className={styles.four}>属于你的非凡“橙”心吧！</p>
        </div>
        <div className={styles.cz}>
          <div onClick={props.goNext} className={styles.dl}>
            <span className={styles.one}>点</span>
            <span className={styles.two}>亮</span>
          </div>
          <img src={cz} alt="" />
        </div>
        <div className={styles.bottom_cz}>
          <img src={czOne} alt="" className={styles.cz_one} />
          <img src={czBg} alt="" className={styles.cz_bg} />
        </div>
      </div>
    </div>
  );
};
export default View;
