import React from 'react';
import { Result } from 'antd-mobile';
import 'antd-mobile/dist/antd-mobile.css';
import styles from './LoadingComponent.module.scss';

const LoadingComponent = ({ pastDelay, error, timedOut }) => {
  if (error) {
    return <Result title='加载失败' message='请刷新页面'/>;
  } else if (timedOut) {
    return <Result title='请求超时' message='请刷新页面'/>
  } else if (pastDelay) {
    return <p className={styles.loadingComponent}>Loading...</p>;
  }
  return null;
};

export default LoadingComponent;